@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/**************/
/* Containers */
/**************/
.container {
    width: 100%;
    @apply mx-auto;
}

.container {
    max-width: 800px;
    @apply py-0 px-[5%];
}

body {
    background-image: url("background.jpg");
}
label {
    @apply block mb-2 text-sm font-medium text-gray-900;
}
input,
textarea {
    @apply bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}
button, .btn {
    @apply select-none rounded-6 bg-[#652566] border-[#ae55af] border-2 text-white py-12 px-20 text-center align-middle font-sans text-16 font-semibold shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none;
}
p {
    @apply text-18 text-balance;
}
nav {
    a {
        @apply py-4;
        @apply text-20 md:text-16;
        @apply border-b-2 border-transparent;
    }
    a.selected {
        @apply font-semibold;
        @apply md:border-white;
    }
}

.react-confirm-alert {
    .confirm-alert {
        @apply max-w-[500px];
        @apply mx-[5%];
        @apply bg-white;
        @apply shadow-[0_0_30px_0_rgba(0,0,0,0.3)];
        @apply px-20 py-16;
        @apply rounded-8;
    }
}
.ReactModal__Overlay {
    background-color:rgba(255, 255, 255, 0.95) !important;
}
.ReactModal__Content {
    @apply border-none;
        @apply bg-white;
        @apply shadow-[0_0_30px_0_rgba(0,0,0,0.3)];
        @apply px-20 py-16;
        @apply rounded-8;
        @apply overflow-auto;
        @apply max-h-[90vh];
        @apply md:mt-[5vh];
}
.react-confirm-alert-overlay {
    opacity:1;
    animation:none;
}
.uppy-Dashboard-dropFilesHereHint {
}

#canva {
    @apply flex-grow;
    @apply relative;


    /* position: relative; width: 100%; height: 0; padding-top: 80.0000%; padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; border-radius: 8px; will-change: transform; */
    iframe {
        position: absolute; width: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;

        /* height: calc(100% - 58px); */
        height: 100%;
    }
}